@import "antd/dist/antd.css";
/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

#components-layout-demo-side .logo {
	height: 32px;
	margin: 16px;
	background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
	background: #fff;
}

a {
	color: #40a9ff
}

.loading-wrapper {
	margin: 20px 0;
  padding: 30px 50px;
  text-align: center;
}
